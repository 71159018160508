<template>
    <v-app>
        <v-main>
            <InventarioDocumental />
        </v-main>
    </v-app>
    
</template>
<script>
import InventarioDocumental from '../components/InventarioDocumental.vue'
export default {
    components: {
        InventarioDocumental
    },
    data(){
        return {

        }
    },
    created(){
        console.log('xd')
    }
}
</script>