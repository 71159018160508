<template>   
    <v-sheet
    elevation="8"
    max-width="400"
    class="mx-auto"
    >
        <Toolbar @activar="$emit('empalmar')" class="mb-2" :titulo="titulo" :activar_tool="activar_empalme_segun_sector()" :icono="'mdi-text-box-plus-outline'" :accion="'Empalmar expedientes'" />
        <v-text-field
            v-model="busar_carpeta"
            filled rounded dense
            label="Buscar expediente"
        ></v-text-field>
        <v-virtual-scroll
            :bench="0"
            :items="expedientes_filtrados"
            height="650"
            item-height="64"
        >
            <template v-slot:default="{ item }">
                
                    <v-list-item @click="$emit('seleccion',item)">
                        <v-list-item-avatar>
                            <v-icon class="grey lighten-1" dark color="yellow">mdi-folder</v-icon>
                        </v-list-item-avatar>

                        <v-list-item-content>
                            <v-list-item-title v-text="item.expediente"></v-list-item-title>

                        </v-list-item-content>

                        <v-list-item-action>
                        <v-btn icon>
                            <v-icon color="grey lighten-1">mdi-information</v-icon>
                        </v-btn>
                        </v-list-item-action>
                    </v-list-item>
                
                <v-divider></v-divider>
            </template>
        </v-virtual-scroll>
    </v-sheet>
       
</template>
<script>
export default {
    props: {
       expedientes: {
           type: Array,
           required: true
       },
       titulo: {
           type: String,
           required: true,
       }
    },
    data(){
        return {
            selected: 0,
            busar_carpeta: null,
            expedientes_filtrados: [],
        }
    },
    watch: {
        busar_carpeta(val){
            
            val = val.toLowerCase()
            
            this.expedientes_filtrados = this.expedientes.filter( item => item.expediente.toLowerCase().indexOf(val) !== -1 )
        },
        expedientes(val){
            console.log(val)
            this.expedientes_filtrados = [...val]
        }
    },
    created(){
        this.expedientes_filtrados = [...this.expedientes]
    },
    methods: {
        activar_empalme_segun_sector(){
            
            if(this.$store.state.dataUser.dataUser.nombre_sector.toLowerCase()=='archivadores' || this.$store.state.dataUser.dataUser.nombre_sector.toLowerCase()=='archivador'){
                 return true
            }else{
                return false
            }
           
        }
    }
    
}
</script>