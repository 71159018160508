<template>
    <v-form class="mx-auto">
        <v-row>
            <v-col cols="12" sm="4">
                <v-text-field label="Codigo documental" v-model="campos.codigo_documental"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field label="Nombre de la serie y asuntos" v-model="campos.nombre_serie"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field type="number" label="Numero consecutivo" v-model="campos.numero_consecutivo"  filled rounded dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            
            <v-col cols="12" sm="6">
                <Fechas :label="'Fecha inicial'" @fecha="campos.fecha_inicial=$event" />
            </v-col>
            <v-col  cols="12" sm="6" >
                <Fechas :label="'Fecha final'" @fecha="campos.fecha_final=$event" />
            </v-col>
        </v-row>
        <v-row >
            <v-col  cols="12" sm="3">
                <v-text-field label="Caja unidad conservacion" v-model="campos.caja_unidad_conservacion"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field label="Carpeta unidad conservacion" v-model="campos.carpeta_unidad_conservacion"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field label="Tomo unidad conservacion" v-model="campos.tomo_unidad_conservacion"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="3">
                <v-text-field label="Otro unidad conservacion" v-model="campos.otro_unidad_conservacion"  filled rounded dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-auto" cols="12" sm="4">
                 <v-text-field label="Numero de folios " v-model="campos.numero_folios"  filled rounded dense></v-text-field>
            </v-col>
            <v-col class="mx-auto" cols="12" sm="4">
                 <v-text-field label="Soporte " v-model="campos.soporte"  filled rounded dense></v-text-field>
            </v-col>
            <v-col class="mx-auto" cols="12" sm="4">
                 <v-text-field label="Frecuencia de consulta " v-model="campos.frecuencia_consulta"  filled rounded dense></v-text-field>
            </v-col>
           
        </v-row>
        <v-row>
            <v-col class="mx-auto" cols="12" sm="8">
                <v-textarea filled dense v-model="campos.observaciones" label="Observaciones" rows="2" >

                </v-textarea>
            </v-col>
            
        </v-row>
        <v-row>
            <v-col cols="12" sm="4">
                <v-text-field label=" Modulo Signatura topografica" v-model="campos.signatura_topografica_modulo"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field label=" Estante signatura topografica" v-model="campos.signatura_topografica_estante"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4">
                <v-text-field label=" Entrepaño signatura topografica " v-model="campos.signatura_topografica_entrepaño"  filled rounded dense></v-text-field>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="mx-auto" cols="12" sm="4">
                <v-text-field label=" Caja unidad conservacion" v-model="campos.signatura_topografica_caja"  filled rounded dense></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" class="mx-auto">
                <v-text-field label=" Carpeta unidad conservacion" v-model="campos.signatura_topografica_carpeta"  filled rounded dense></v-text-field>
            </v-col>
        </v-row>

        <v-row>
            <v-btn block color="indigo daken-4" dark @click="enviar_datos_fuid"> Guardar datos <v-icon> mdi-upload </v-icon> </v-btn>
        </v-row>
        
    </v-form>
</template>
<script>
import Fechas from './atomos/Fechas.vue'
export default {
    components: {
        Fechas,
    },
    data(){
        return {
            campos: {
                codigo_documental: "",
                nombre_serie: "",
                numero_consecutivo: "",
                fecha_inicial: "",
                fecha_final: "",
                caja_unidad_conservacion: "",
                carpeta_unidad_conservacion:"",
                tomo_unidad_conservacion: "",
                otro_unidad_conservacion: "",
                numero_folios: "",
                soporte: "",
                frecuencia_consulta: "",
                observaciones: "",
                signatura_topografica_modulo: "",
                signatura_topografica_estante: "",
                signatura_topografica_entrepaño: "",
                signatura_topografica_caja: "",
                signatura_topografica_carpeta: "",
                fecha_creacion_int: null,

            }
        }
    },
    methods: {
        enviar_datos_fuid(){
            this.campos.fecha_creacion_int = Date.parse(new Date())
            this.$emit('campos_fuid',this.campos)

            this.campos = {
                codigo_documental: null,
                nombre_serie: null,
                numero_consecutivo: null,
                fecha_inicial: null,
                fecha_final: null,
                caja_unidad_conservacion: null,
                carpeta_unidad_conservacion:null,
                tomo_unidad_conservacion: null,
                otro_unidad_conservacion:null,
                numero_folios:null,
                soporte: null,
                frecuencia_consulta: null,
                observaciones: null,
                signatura_topografica_modulo: null,
                signatura_topografica_estante:null,
                signatura_topografica_entrepaño: null,
                signatura_topografica_caja: null,
                signatura_topografica_carpeta: null,
            }
        }
    }
}
</script>