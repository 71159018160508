<template>
    <v-app>
        <v-main class="ma-5">
            <Toolbar :titulo="'Modulo de empalme de plataformas'" :activar_tool="false" />
            <v-row class="mx-10">
                <v-col cols="12">
                    <v-card  v-on:keydown.enter="buscar()"  >
                        <Toolbar :titulo="'Buscar informacion'" 
                        @activar="dialogo_agregar=true"
                        
                        :activar_tool="false"
                        :accion="'Subir dattos'"
                        :icono="'mdi-upload'"  
                        />
                        <Load v-show="load" />
                        <v-row class="mx-5">
                            <v-col>
                                <v-text-field 
                                    v-model="busqueda"
                                    append-icon="mdi-magnify" 
                                    @click:append="buscar()" label="Buscar numero" 
                                    filled rounded dense
                                    
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mx-3">
                            <v-col cols="12" sm="6" v-if="mostrar_elementos_dom==true && load!=null ">
                                <Lista @empalmar="dialogo_empalmar_archivos=true" @seleccion="elemento_lista_seleccionado($event)" :titulo="'Expedientes'" :expedientes="archivos_encontrados.expedientes" />
                            </v-col>
                            <v-col cols="12" sm="6" v-if="mostrar_elementos_dom==true && load!=null ">
                                    <v-row>
                                        <Toolbar @activar="descargar_archivos()" :titulo="'Expediente: '+archivos_encontrados.expedientes[expediente_seleccionado].expediente" :activar_tool="true" :icono="'mdi-download'" :accion="'Descargar todo'" />
                                    </v-row>
                                    <v-row>
                                        <v-col class="mx-auto" cols="12" sm="8">
                                            <Paginacion :cantidad_paginas="archivos_encontrados.expedientes[expediente_seleccionado].archivos.length" class="my-3" @buscar="pagina=$event-1" />
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col>
                                            <v-window v-model="pagina">
                                                <v-window-item v-for="(archivo, k) in archivos_encontrados.expedientes[expediente_seleccionado].archivos" :key="k"  >
                                                    <v-sheet
                                                        class="mx-auto"
                                                        max-width="75%"
                                                        color=" "
                                                        elevation=""
                                                    >
                                                        <v-container>
                                                        <v-row>
                                                            <v-col>
                                                            <Archivos
                                                                :mostrar_carpeta_publica="true"
                                                                :archivo="archivo"
                                                                :ruta="ruta_archivos"
                                                                :width="800"
                                                                :height="500"
                                                            />
                                                            </v-col>
                                                        </v-row>
                                                        </v-container>
                                                    </v-sheet>
                                                </v-window-item>
                                            </v-window>
                                        
                                        </v-col>
                                    </v-row>
                            </v-col>
                            <v-col v-else>
                                <NotFound :mensaje="'No hay archivos para mostrar'" />
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
                <v-dialog v-model="dialogo_empalmar_archivos" persistent max-width="800" >
                    <Card @activar="dialogo_empalmar_archivos=false" :titulo="'Seleccciona el tipo documental de los archivos'">
                        <template v-slot:contenido>
                            <Load v-show="load_empalme" />
                            <v-row class="mx-5">
                                <v-col>
                                    <v-autocomplete
                                        @change="parametros_tipo_documental_seleccionado"
                                        prepend-icon="mdi-file-document"
                                        return-object
                                        item-text="nombre"
                                        :items="tipos_documentales"
                                        label="Selecciona el tipo documental"
                                        v-model="tipo_documental_seleccionado"
                                        rounded
                                        filled
                                        dense
                                    />
                                </v-col>
                            </v-row>
                            <v-row class="mx-5">
                                <v-col> 
                                    <InputsTipoDocumental />
                                </v-col>
                            </v-row>
                            <v-row class="mx-8">
                                <v-btn @click="empalmar_archivos()" 
                                    :disabled="tipo_documental_seleccionado==null" 
                                    dark large block color="indigo darken-4"
                                > 
                                    Subir archivos 
                                </v-btn>
                            </v-row>
                        </template>
                    </Card>
                </v-dialog>
                
            </v-row>
            <!---
            <v-dialog max-width="600" v-model="dialogo_agregar">
                <Card :titulo="'Agregar pruebas'" @activar="dialogo_agregar=false" >
                    <template v-slot:contenido>
                        <EmpalmePrueba/>
                    </template>
                </Card>
            </v-dialog>
            -->
            
        </v-main>
    </v-app>
</template>
<script>
//import EmpalmePrueba from '../components/demo/EmpalmePrueba.vue'
import Archivos from '../components/Archivos.vue'
import InputsTipoDocumental from '../components/formularios/InputsTipoDocumental.vue'
import Lista from '../components/comunes/Lista.vue'
import  jsPDF  from "jspdf";
import { EventBus } from '../event/event-bus'
import folders from '../mixins/folders'
export default {
    components: {
        //EmpalmePrueba,
        Archivos,
        InputsTipoDocumental,
        Lista,
    },
    mixins: [folders],
    data(){
        return {
            busqueda: "",
            load: null,
            load_empalme: false,
            dialogo_agregar: false,
            

            archivos_encontrados: {
                nombre_carpeta: "",
                expedientes: [
                    {archivos: []}
                ]
                    
            },

            mostrar_elementos_dom: false,


            expediente_seleccionado: 0,
            pagina: 0,
            dialogo_empalmar_archivos: false,
            

            tipos_documentales: [],

            tipo_documental_seleccionado: null,
            ruta_archivos: 'https://drive.educandote.co/audidoc',
           
        }
    },
    created(){
        console.log('xdssssssssssss')
        this.consultar_tipos_documentales()
    },
    watch:{
        expediente_seleccionado(val){
            console.log(this.archivos_encontrados.expedientes[val].archivos)   
            console.log(val)
            EventBus.$emit('cambiar_pagina',0)
        }
    },
    methods: {
        elemento_lista_seleccionado(elemento){
            console.log(elemento)
            console.log(this.archivos_encontrados.expedientes)
            let index = this.archivos_encontrados.expedientes.findIndex( (expediente) => expediente.expediente == elemento.expediente  )
            console.log(index)
            this.expediente_seleccionado = index
            console.log(this.expediente_seleccionado)
        },
       async descargar_archivos(){
           this.$store.state.dialogCargando = true
           
           try {
               console.log('empalmsar')
                console.log(this.archivos_encontrados.expedientes[this.expediente_seleccionado])
                var doc = new jsPDF('p', 'mm','a4');
                
                
                let altura = 0
                for (let index = 0; index < this.archivos_encontrados.expedientes[this.expediente_seleccionado].archivos.length; index++) {
                    doc.addImage(
                        this.urlRespuestas(
                            this.archivos_encontrados.expedientes[this.expediente_seleccionado].archivos[index],
                            this.ruta_archivos
                            )
                        ,this.archivos_encontrados.expedientes[this.expediente_seleccionado].archivos[index].tipo ,0, altura,200 ,280
                        )

                        //Evita que se inserte una pagina en blanco al final 
                        if(index+1 != this.archivos_encontrados.expedientes[this.expediente_seleccionado].archivos.length ){
                            doc.addPage('a4','p')
                        }
                        
                }
                
                doc.save('Carpeta '+ this.archivos_encontrados.expedientes[this.expediente_seleccionado].expediente+'.pdf');
           } catch (error) {
               console.log(error)
               this.$store.commit(
                    "error",
                    "Ha ocurrido un error inesperado en el momento de descargar una imagn "+error.message
                );
           }
           
           this.$store.state.dialogCargando = false
       },
       async empalmar_archivos(){
           this.load_empalme = true
            try {
                let obj = await this.concatenarDatos()
                console.log(obj)
                obj = JSON.parse(obj);
                console.log(obj);
                obj.fecha_creacion = Date.parse( new Date ()) 
               
                obj.datos_archivos = [];
                console.log(this.tipo_documental_seleccionado)

                console.log(this.$store.state.formularios.inputs_tipo_documental)
                let aux_meta_datos = []
                for (let index = 0; index < this.$store.state.formularios.inputs_tipo_documental.campos.length; index++) {
                    aux_meta_datos.push(JSON.stringify(this.$store.state.formularios.inputs_tipo_documental.campos[index])) 
                }
                obj.carpetas = []
                

                console.log(this.archivos_encontrados.expedientes)

                
                let carpeta_inicial = {
                    nombre: "Datos adjuntos",
                    meta_datos: [...this.$store.state.formularios.inputs_tipo_documental.campos],
                    archivos: [],
                    estado: 'habilitada'
                }
                carpeta_inicial = JSON.stringify(carpeta_inicial)
                console.log(carpeta_inicial)
                obj.carpetas.push(carpeta_inicial)

                let carpeta_expedientes = {}

                for (let index = 0; index < this.archivos_encontrados.expedientes.length; index++) {
                    
                    carpeta_expedientes = {
                        nombre: this.archivos_encontrados.expedientes[index].expediente,
                        meta_datos: [...this.$store.state.formularios.inputs_tipo_documental.campos],
                        archivos: this.archivos_encontrados.expedientes[index].archivos,
                        estado: 'habilitada'
                    }
                    carpeta_expedientes = JSON.stringify(carpeta_expedientes)
                    obj.carpetas.push(carpeta_expedientes)
                }


              
                console.log(obj.carpetas)
                console.log(obj)
                console.log('xd')
               
                let empalme = await this.$store.dispatch('llamado_post',{
                    url: "https://audidoc.educandote.co/types/collections/"+this.tipo_documental_seleccionado.nombre_endpoint_almacenamiento+"/documents",
                    tipo_header: "indexador",
                    body: obj
                })
                console.log(empalme)
                this.$store.commit(
                    "exitoso",
                    "Los archivos han sido almacenados exitosamente"
                );
               

                this.mostrar_elementos_dom = false
                this.archivos_encontrados = {
                    nombre_carpeta: "",
                    expedientes: [
                        {archivos: []}
                    ]
                }
                
               
                this.tipo_documental_seleccionado = null
                this.$store.state.formularios.inputs_tipo_documental = {
                    campos: [],
                };
                this.dialogo_empalmar_archivos = false
                
            } catch (error) {
                console.log(error)
                this.$store.commit(
                "error",
                "Ha ocurrido un error inesperado en el momento de almacenar los datos en la coleccion "+error.message
                );
            }
            this.load_empalme = false
        },
        async concatenarDatos() {
            let obj = "";
            for (
                let index = 0;
                index <
                this.$store.state.formularios.inputs_tipo_documental.campos.length;
                index++
            ) {
                obj = obj + `"${this.$store.state.formularios.inputs_tipo_documental.campos[index].key}":"${
                                this.$store.state.formularios.inputs_tipo_documental.campos[index].value
                            }",`;
            }
            let objeto = obj.substring(0, obj.length - 1)
            //obj = obj +`"fecha_limite":"${this.$store.state.formularios.inputs_tipo_documental.fecha_limite}"`;
            console.log(objeto);
            obj = "{" + objeto + "}";
            console.log(this.$store.state.formularios.inputs_tipo_documental)

            return obj;
        },
        parametros_tipo_documental_seleccionado(){
                this.$store.state.formularios.inputs_tipo_documental = {
                    campos: [],
                };
                
                console.log(this.tipo_documental_seleccionado)
                
                for (let index = 0; index < this.tipo_documental_seleccionado.parametros.length; index++) {
                  this.tipo_documental_seleccionado.parametros[index].value = ""    
                }
            this.$store.state.formularios.inputs_tipo_documental.campos = [...this.tipo_documental_seleccionado.parametros]
               
        },
        async consultar_tipos_documentales() {
            try {
                let tipos_documentales = await this.$store.dispatch('llamado_get',{
                    url:"https://audidoc.educandote.co/parse/classes/V1_tiposDocumentales",
                    tipo_header:"parse"
                })
                console.log(tipos_documentales)
                this.tipos_documentales = [...tipos_documentales.data.results]
            } catch (error) {
                console.log(error)
            }
            
        },
        async buscar(){
            this.load=true
            this.archivos_encontrados = {
                nombre_carpeta: "",
                expedientes: [
                    {archivos: []}
                ]
            }
            this.expediente_seleccionado = 0
            
        
            try {
                console.log(this.busqueda)
                let response = await this.$store.dispatch('llamado_get',{
                    url: "https://audidoc.educandote.co/types/collections/"+'pruebafry_archivos_empalme04'+"/documents/search?q="+this.busqueda+"&query_by="+'carpeta'+"&filter_by="+'carpeta'+":["+this.busqueda+"]&per_page=250&page=1",
                    tipo_header:"indexador"
                })
                console.log(response)
                //let expedientes = []

                    response.data.found > 0 ? this.mostrar_elementos_dom = true : this.mostrar_elementos_dom = false  

                    let carpeta = {
                        nombre_carpeta: this.busqueda,
                        expedientes: []
                    }
                    for (let index = 0; index < response.data.hits.length; index++) {

                        let expediente_parse= JSON.parse(response.data.hits[index].document.expedientes[0])
                        carpeta.expedientes = [...expediente_parse.carpetas,...carpeta.expedientes]

                    }
                   
                     console.log(carpeta.expedientes)
                    let carpeta_ordenada = [...carpeta.expedientes]
                    
                    for (let index = 0; index < carpeta_ordenada.length; index++) {
                        
                        for (let index2 = 0; index2 < carpeta_ordenada[index].archivos.length; index2++) {
                        
                            let posicion = carpeta_ordenada[index].archivos[index2].nombre.split('Imagen')
                            console.log(posicion)
                            carpeta_ordenada[index].archivos[index2].posicion = parseInt(posicion[1]) 
                        }
                        carpeta_ordenada[index].archivos.sort(function (a, b) {
                        if (a.posicion > b.posicion) {
                            return 1;
                        }
                        if (a.posicion < b.posicion) {
                            return -1;
                        }
                        // a must be equal to b
                        return 0;
                        });
                    }
                    carpeta.expedientes = [...carpeta_ordenada]
                    
                    
                    console.log(carpeta_ordenada)

                this.archivos_encontrados = {...carpeta}
                console.log(this.archivos_encontrados)
               
            } catch (error) {
                console.log(error)
            }
            this.load=false
        }
    }
}
</script>